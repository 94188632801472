<template>
  <div class="layout-wrapper layout-1">
    <b-container class="layout-inner" fluid>
      <app-layout-navbar />

      <div class="layout-container-wrapper">
        <div class="layout-container">
          <div class="layout-content">
            <div class="router-transitions container-fluid flex-grow-1 p-0">
              <router-view />
            </div>

            <app-layout-footer />
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import LayoutNavbarDealership from './LayoutNavbarDealership'
import LayoutFooterDealership from './LayoutFooterDealership'

export default {
  name: 'app-layout-1',
  components: {
    'app-layout-navbar': LayoutNavbarDealership,
    'app-layout-footer': LayoutFooterDealership
  },

  mounted() {
    this.layoutHelpers.init()
    this.layoutHelpers.update()
    this.layoutHelpers.setAutoUpdate(true)
  },

  beforeDestroy() {
    this.layoutHelpers.destroy()
  },

  methods: {
    closeSidenav() {
      this.layoutHelpers.setCollapsed(true)
    }
  }
}
</script>
