<template>
  <div class="layout-footer-wrapper">
    <img
      :src="require('@/assets/img/layout/footer.png')"
      class="bg-image d-none d-md-block"
    />
    <img
      :src="require('@/assets/img/layout/footer-mobile.png')"
      class="bg-image d-md-none"
    />
    <nav class="layout-footer footer" :class="getLayoutFooterBg()">
      <div class="container-fluid p-0">
        <b-row>
          <b-col class="text-center">
            <p class="title">
              Sell, Ship, And Make More Money.<br class="d-none d-md-block" />
              Get Started Now!
            </p>
            <button class="green">Signup Now!</button>
          </b-col>
        </b-row>
        <b-row class="d-flex flex-column-reverse flex-md-row">
          <b-col class="col-12 col-md-5">
            <div class="brand">
              <img :src="require('@/assets/img/logo/nexus-logo-white.svg')" />
              <p class="message">
                Don’t take it from us. Here’s what<br />some of your peers are
                saying
              </p>
            </div>
          </b-col>
          <b-col class="col-12 col-md-7">
            <b-row class="links-part">
              <b-col class="col-12 col-md-4">
                <div class="links">
                  <div class="header" @click="expand('#part-1')">Links</div>
                  <div class="content" id="part-1">
                    <a href="#" class="footer-link">Over ons</a>
                    <a href="#" class="footer-link">Social Media</a>
                    <a href="#" class="footer-link">Counters</a>
                    <a href="#" class="footer-link">Contact</a>
                  </div>
                </div>
              </b-col>
              <b-col class="col-12 col-md-4">
                <div class="company">
                  <div class="header" @click="expand('#part-2')">Company</div>
                  <div class="content" id="part-2">
                    <a href="#" class="footer-link">Terms & Conditions</a>
                    <a href="#" class="footer-link">Privacy Policy</a>
                    <a href="#" class="footer-link">Contact</a>
                  </div>
                </div>
              </b-col>
              <b-col class="col-12 col-md-4">
                <div class="get-in-touch">
                  <div class="header" @click="expand('#part-3')">
                    Get in touch
                  </div>
                  <div class="content" id="part-3">
                    <a href="#" class="footer-link">Address info</a>
                    <a href="#" class="footer-link">044-8595858</a>
                    <a href="#" class="footer-link">info@nexus.com</a>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
.title {
  font-weight: normal;
  font-size: 48px;
  line-height: 142.5%;
  text-align: center;
  letter-spacing: 0.02em;
  margin-top: 300px;
  color: #fff;
}
button.green {
  width: 210px;
  margin-top: 15px;
  margin-bottom: 150px;
}
.brand {
  img {
    width: 100px;
  }
  .message {
    font-weight: normal;
    font-size: 12px;
    line-height: 154%;
    letter-spacing: 0.02em;
    max-width: 204px;
    margin-top: 20px;
  }
}
.links-part {
  margin-bottom: 100px;
  .header {
    background-color: transparent;
    padding: 0vw;
    height: 30px;
    outline: none;
    font-size: 18px;
    font-weight: normal;
    line-height: 106%;
    letter-spacing: 0.02em;
    &:focus {
      box-shadow: none;
    }
  }
  .content {
    font-weight: normal;
    font-size: 18px;
    line-height: 291.5%;
    letter-spacing: 0.02em;
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .title {
    font-weight: normal;
    font-size: 36px;
    line-height: 142.5%;
    text-align: center;
    letter-spacing: 0.02em;
    margin-top: 150px;
    color: #fff;
  }
  button.green {
    width: 100%;
    max-width: 440px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 80px;
  }
  .brand {
    margin-top: 200px;
    margin-bottom: 100px;
  }
  .links-part {
    height: 148px;
    .links,
    .company,
    .get-in-touch {
      .header {
        position: relative;
        padding: 0px;
        height: 50px;
        font-weight: normal;
        font-size: 18px;
        line-height: 50px;
        letter-spacing: 0.02em;
        width: 100%;
        text-align: left;
        outline: none;
        border-top: 1px solid #8698b3;
        border-bottom: 1px solid #8698b3;
        margin-top: -1px;
        &:focus {
          box-shadow: none;
        }
        &:after {
          background: url('~@/assets/img/chevron-down.svg') no-repeat center;
          background-size: contain;
          width: 15px;
          height: 15px;
          position: absolute;
          top: 18px;
          right: 0px;
          content: '';
        }
      }
    }
    .links .content {
      display: block;
    }
  }
}
</style>

<script>
import $ from 'jquery'

export default {
  name: 'app-layout-footer',
  mounted() {
    const $comp = this
    $(window).on('resize', function() {
      if ($(window).width() < 767) {
        $comp.openPart('#part-1')
      } else {
        $comp.openPart('all')
      }
    })
    $(window).trigger('resize')
  },
  data() {
    return {
      expandedPart: '#part-1'
    }
  },
  methods: {
    getLayoutFooterBg() {
      return `bg-${this.layoutFooterBg}`
    },
    expand(partId) {
      if ($(window).width() < 767) {
        this.openPart(partId)
      }
    },
    openPart(partId) {
      if (partId === 'all') {
        this.expandedPart = 'all'
        $('.links-part')
          .find('.content')
          .slideDown()
      } else {
        const content = $(partId)
        content
          .closest('.links-part')
          .find('.content')
          .not(content)
          .slideUp()
        content.slideDown()
        this.expandedPart = partId
      }
    }
  }
}
</script>
