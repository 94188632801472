<template>
  <div class="top-menu">
    <b-navbar
      sticky
      toggleable="md"
      :variant="getLayoutNavbarBg()"
      class="layout-navbar align-items-md-center container-fluid"
    >
      <!-- Brand -->
      <b-navbar-brand to="/">
        <b-img :src="brandLogo" v-bind="{ width: 120 }"></b-img>
      </b-navbar-brand>

      <!-- Navbar toggle -->

      <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

      <b-collapse is-nav id="app-layout-navbar">
        <b-navbar-nav class="mx-auto">
          <b-nav-item class="left-padding"></b-nav-item>
          <b-nav-item to="/">Home</b-nav-item>
          <b-nav-item to="/help">Help</b-nav-item>
        </b-navbar-nav>
        <div class="buttons">
          <button class="blue login mr-0 mr-md-3">Login</button>
          <button class="green request-invite">Request your invite</button>
        </div>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<style lang="scss" scoped>
@media screen and (min-width: 992px) {
  .left-padding {
    width: 250px;
    cursor: default !important;
    a {
      cursor: default !important;
    }
  }
}

.buttons {
  text-align: center;
  button {
    height: 44px !important;
    min-width: 112px;
    line-height: 44px !important;
    padding: 0px 20px;
    font-size: 12px;
    border-radius: 14px !important;
  }
}
@media screen and (max-width: 767px) {
  .buttons {
    button {
      margin: 10px 0px;
      max-width: 440px;
      width: 100%;
    }
  }
}
</style>

<script>
import $ from 'jquery'

export default {
  name: 'app-layout-navbar',
  data() {
    return {
      brandLogo: require('@/assets/img/logo/nexus-logo-white.svg')
    }
  },
  mounted() {
    const $comp = this

    $(window).scroll(function() {
      const scrollTop = $(window).scrollTop()
      let topMargin = 0

      if ($(window).width() < 768) {
        topMargin = 10
      } else {
        topMargin = 45
      }
      if (scrollTop >= topMargin) {
        $('.top-menu').addClass('scrolling')
        $comp.brandLogo = require('@/assets/img/logo/nexus-logo-blue.svg')
      } else {
        $('.top-menu').removeClass('scrolling')
        $comp.brandLogo = require('@/assets/img/logo/nexus-logo-white.svg')
      }
    })
  },
  methods: {
    getLayoutNavbarBg() {
      return this.layoutNavbarBg
    }
  }
}
</script>
